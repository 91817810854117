import './style.css';

import CustomMap from "./custommap";
import receiveData from "./receiver";

const CENTER_MAP = [19, 42.73];

document.addEventListener("DOMContentLoaded", (event) => {

  var modal = document.getElementById("imageModal");
  var img = document.getElementById("img01");
  var span = document.getElementsByClassName("close")[0];

  // Функция для показа изображения
  window.showImage = function(imageUrl) {
    img.src = imageUrl;
    modal.style.display = "block";
  }

  window.onclick = function(event) {
    if (event.target === modal) {
      modal.style.display = "none";
    }
  }

  // Закрытие модального окна при клике на крестик
  span.onclick = function() {
    modal.style.display = "none";
  }


  let mapInstance;

  const container = document.getElementById('popup');
  const content = document.getElementById('popup-content');
  const closer = document.getElementById('popup-closer');

  mapInstance = new CustomMap(container,content,closer, CENTER_MAP);
  console.log( mapInstance)

  Promise.all([receiveData()]).then(data => {
    console.log(data)
    mapInstance.addMarkers(data[0])
  });


});
